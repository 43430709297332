import { Component, OnInit } from '@angular/core';
import { FileUploadService } from '../../services/file-upload.service';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'app-edit-questions',
  templateUrl: './edit-questions.component.html',
  styleUrls: ['./edit-questions.component.scss']
})
export class EditQuestionsComponent implements OnInit {

  currentValue: string = ''; 
  questionsValue: string = ''; 
  answerValue: string = '';
  languageValue: string = 'es';
  questions: any[] = [];


  constructor(
    private _fileUploadService: FileUploadService,
    private _snackbarService: SnackbarService,
    private _pageLoadingService: PageLoadingService
  ) { }

  ngOnInit(): void {
    this.loadQuestions()
  }

  loadQuestions(): void {
    this._pageLoadingService.showLoadingGif();
    this._fileUploadService.getQuestions().subscribe(
      (data: any[]) => {
        this.questions = data;
        this._pageLoadingService.hideLoadingGif();
      },
      (error: any) => {
        this._pageLoadingService.hideLoadingGif();
      }
    );
  }

  onQuestionInput(event: Event): void {
    const textareaElement = event.target as HTMLTextAreaElement;
    this.questionsValue = textareaElement.value;
  }

  onAnswerInput(event: Event): void {
    const textareaElement = event.target as HTMLTextAreaElement;
    this.answerValue = textareaElement.value;
  }

  onLanguageChange(event: any): void {
    this.languageValue = event.value; 
    // this.translate.use(this.languageValue); 
  }

  deleteQuestion(id: any): void {
    this._pageLoadingService.showLoadingGif();
    this._fileUploadService.deleteQuestion(id).subscribe(
      response => {
        if (response.statusCode === 200) {
          this._snackbarService.openStandardSnackBar('questions_delete_success');
          this._pageLoadingService.hideLoadingGif();
          this.loadQuestions();
        }else {
          this._snackbarService.openStandardSnackBar('questions_delete_error');
        }
      }
    );
  }

  submit(): void {
    // Verifica si ambos campos tienen contenido antes de enviar la solicitud
    if (this.questionsValue.trim() === '' || this.answerValue.trim() === '') {
      this._snackbarService.openStandardSnackBar('questions_validate');
      return;
    } else {
      this._pageLoadingService.showLoadingGif();

    }

    const questionData = {
      question: this.questionsValue,
      answer: this.answerValue,
      language: this.languageValue
    };

    
    this._fileUploadService.createQuestions(questionData).subscribe(response => {
      if(response.statusCode === 200) {
        this._snackbarService.openStandardSnackBar('questions_success');
        this._pageLoadingService.hideLoadingGif();
        this.loadQuestions()
      } else {
        this._snackbarService.openStandardSnackBar('questions_error');
        this._pageLoadingService.hideLoadingGif();
      }
    });
  }
}
