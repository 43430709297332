<div class="principalContainer">
  <h2 class="text-center">{{ 'payment-report.productsTitle' | translate }}</h2>
  <div class="container mt-5 d-flex flex-column align-items-center">
    <button class="btn mb-3 btnClose" (click)="closeReport()">X</button>
    <div>
      <table class="styled-table">
        <thead>
          <tr>
            <th>{{ 'payment-report.productName' | translate }}</th>
            <th>{{ 'payment-report.productCode' | translate }}</th>
            <th>{{ 'payment-report.productPrice' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let product of productsList">
            <td>{{ product.title }}</td>
            <td>{{ product.interactiveCode }}</td>
            <td>{{ product.amount }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Información Combinada -->
    <div class="mt-5 w-100 d-flex align-items-center">
      <table class="styled-table" *ngIf="shippingData">
        <tbody>
          <!-- Información de Envío y Facturación -->
          <tr>
            <td class="shippingDesc">{{ 'shippping_info.shipping_full_name' | translate }}</td>
            <td>{{ shippingData.shipping_fullName }}</td>
            <td class="shippingDesc">{{ 'shippping_info.billing_full_name' | translate }}</td>
            <td>{{ shippingData.billing_fullName }}</td>
          </tr>
          <tr>
            <td class="shippingDesc">{{ 'shippping_info.shipping_email' | translate }}</td>
            <td>{{ shippingData.shipping_email }}</td>
            <td class="shippingDesc">{{ 'shippping_info.billing_email' | translate }}</td>
            <td>{{ shippingData.billing_email }}</td>
          </tr>
          <tr>
            <td class="shippingDesc">{{ 'shippping_info.shipping_country' | translate }}</td>
            <td>{{ shippingData.shipping_country }}</td>
            <td class="shippingDesc">{{ 'shippping_info.billing_country' | translate }}</td>
            <td>{{ shippingData.billing_country }}</td>
          </tr>
          <tr>
            <td class="shippingDesc">{{ 'shippping_info.shipping_address' | translate }}</td>
            <td>{{ shippingData.shipping_address }}</td>
            <td class="shippingDesc">{{ 'shippping_info.billing_address' | translate }}</td>
            <td>{{ shippingData.billing_address }}</td>
          </tr>
          <tr>
            <td class="shippingDesc">{{ 'shippping_info.shipping_id_number' | translate }}</td>
            <td>{{ shippingData.shipping_idNumber }}</td>
            <td class="shippingDesc">{{ 'shippping_info.billing_id_number' | translate }}</td>
            <td>{{ shippingData.billing_idNumber }}</td>
          </tr>          
          
          <!-- Mensajes si no hay datos -->
          <tr *ngIf="!shippingData">
            <td colspan="2">{{ 'payment-report.noInfo' | translate }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
