import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReportService } from 'src/app/report/services/report.service';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';
import { CommonResponse } from 'src/app/shared/models/reponse.model';
import { paidInfo } from '../../../books/models/paidProducts.model';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { UserService } from 'src/app/users/services/user.service';

@Component({
  selector: 'app-list-order-books',
  templateUrl: './list-order-books.component.html',
  styleUrls: ['./list-order-books.component.scss']
})
export class ListOrderBooksComponent implements OnInit {

  paidProductsData: paidInfo;
  language: string;
  productsList: { title: string, interactiveCode: string, amount: string }[] = [];
  shippingData: any;  // Usamos 'any' para evitar restricciones de tipo

  readonly shippingInfoObserver = {
    next: (data: CommonResponse<any>) => this.getShippingInfoNext(data),
    error: (error: CommonResponse<any>) => this.getShippingInfoError(error)
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { paymentId: number },
    private dialogRef: MatDialogRef<ListOrderBooksComponent>,
    private _reportService: ReportService,
    private _pageLoadingService: PageLoadingService,
    private _snackbarService: SnackbarService,
    private _userService: UserService,
  ) {
    this.setValuelanguaje();
  }

  ngOnInit(): void {
    this._userService.getShippingInfo(this.data.paymentId).subscribe(this.shippingInfoObserver);

    this._reportService.getPaymentProducts(this.data.paymentId, this.language).subscribe({
      next: (response: CommonResponse<any>) => {
        if (response.statusCode === 200) {
          this.paidProductsData = response.data.original;
          this.showPaidProducts();
        } else {
          this._snackbarService.openStandardSnackBar('paidProductsError');
          this._pageLoadingService.hideLoadingGif();
        }
      },
      error: (error: any) => {
        console.error('Error al obtener productos pagados:', error);
        this._snackbarService.openStandardSnackBar('paidProductsError');
      },
      complete: () => this._pageLoadingService.hideLoadingGif()
    });
  }

  showPaidProducts() {
    this.productsList = this.paidProductsData.productTitles.map((title: string, index: number) => ({
      title,
      interactiveCode: this.paidProductsData.interactiveCodes[index],
      amount: this.paidProductsData.amounts[index]
    }));
  }

  getShippingInfoNext(data: CommonResponse<any>) {
    this.shippingData = data.data.shippingInfo;
  }

  getShippingInfoError(error: CommonResponse<any>) {
    console.log(error);
  }

  setValuelanguaje() {
    this.language = sessionStorage.getItem('selectedLanguage') ?? 'es';
    this.language = this.language === 'es' ? 'spa' : 'eng';
  }

  closeReport() {
    this.dialogRef.close();
  }
}
