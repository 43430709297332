import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { LoginService } from 'src/app/auth/services/logIn.service';
import { UserService } from '../../services/user.service';
import { CommonResponse } from 'src/app/shared/models/reponse.model';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent {

  userForm: FormGroup;
  changePasswordUser: FormGroup;
  accountName: string;
  userId: number;
  toggleForms: boolean = false;
  @ViewChild('passwordInput') passwordInput!: ElementRef;
  @ViewChild('passwordConfirmInput') passwordConfirmInput!: ElementRef;

  private readonly getAllUsersObserver = {
    next: (data: CommonResponse<any>) => this.getAllInfoUsersNext(data),
    error: (error: CommonResponse<any>) => this.getAllInfoUsersError(error),
  };

  constructor(
    public dialogRef: MatDialogRef<MyAccountComponent>,
    private _formBuilder: FormBuilder,
    private _loginService: LoginService,
    private _userService: UserService,
    private _pageLoadingService: PageLoadingService,
    private _snackbarService: SnackbarService,
  ) { 

    const user = this._loginService.getLoggedUser();
    
    if (user) {
      this.accountName = user.email;
      this.userId = user.userId;

      this._userService.getUserInfo(user.userId)
      .subscribe(this.getAllUsersObserver);
    }

    this.userForm = this._formBuilder.group({
      'userName': new FormControl(''),
      'email': new FormControl(''),
      'adress': new FormControl(''),
      'phoneIndicative': new FormControl(''),
      'phoneNumber' : new FormControl(''),
    });

    this.changePasswordUser = this._formBuilder.group({
      'newPassWord': ['', Validators.required],
      'confirmPassword': ['', Validators.required]
    });
  }

  togglePasswordVisibility() {
    const inputPassword = this.passwordInput.nativeElement as HTMLInputElement;
    inputPassword.type = inputPassword.type === 'password' ? 'text' : 'password';

    const inputPasswordConfirm = this.passwordConfirmInput.nativeElement as HTMLInputElement;
    inputPasswordConfirm.type = inputPasswordConfirm.type === 'password' ? 'text' : 'password';
  }

  toggleFomrsFunction() {
    this.toggleForms = true
  }

  generateFormDataRequest() {
    let requestFormData = new FormData();

    requestFormData.set('userName', this.userForm.get('userName')?.value)
    requestFormData.set('email', this.userForm.get('email')?.value)
    requestFormData.set('adress', this.userForm.get('adress')?.value)
    requestFormData.set('phoneIndicative', this.userForm.get('phoneIndicative')?.value)
    requestFormData.set('phoneNumber', this.userForm.get('phoneNumber')?.value)

    return requestFormData
  }

  getAllInfoUsersNext(data: CommonResponse<any>) {
    let infoUserData = data.data

    this.userForm?.get('userName')?.setValue(infoUserData[0].user.name);
    this.userForm?.get('email')?.setValue(this.accountName);
    this.userForm?.get('adress')?.setValue(infoUserData[0].adress);
    this.userForm?.get('phoneIndicative')?.setValue(infoUserData[0].numberIndicator);
    this.userForm?.get('phoneNumber')?.setValue(infoUserData[0].phoneNumber);
     
  }

  getAllInfoUsersError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();

    if(error.statusCode == 404) {
      this._snackbarService.openStandardSnackBar('getAllUsersError404');
    }
    else {
      this._snackbarService.openStandardSnackBar('getAllUsersError');
    }
  }

  onSubmit() {
    this._userService.updateUserInfo(this.generateFormDataRequest(), this.userId).subscribe((data) => {
      this._pageLoadingService.hideLoadingGif();
      this._snackbarService.openStandardSnackBar('succesUpdateUSer', 'Ok');
    },); 
  }

  close() {
    this.dialogRef.close(true)
  }

  back() {
    this.toggleForms = false
  }
  

  // function to validate passwords
  comparePasswords() {
    let requestFormPasswordData = new FormData();

    const password = this.changePasswordUser?.get('newPassWord')?.value;
    const confirmPassword = this.changePasswordUser?.get('confirmPassword')?.value;

    if ( password === confirmPassword) {
      requestFormPasswordData.set('newPassWord', this.changePasswordUser.get('newPassWord')?.value)

      return requestFormPasswordData
    } else {
      this._snackbarService.openStandardSnackBar('confirm_passwords');
      return false;
    }    
  }

  onSubmitPassword() {
    const formData = this.comparePasswords();
    if (formData) {
        this._userService.updateUserPassword(formData, this.userId).subscribe((data) => {
            this._pageLoadingService.hideLoadingGif();
            this._snackbarService.openStandardSnackBar('password_succes');
        });
    }
  }
}


