import { Component, OnInit } from '@angular/core';
import { CommonResponse } from 'src/app/shared/models/reponse.model';
import { environment as env } from 'src/environments/environment';
import { documentsList } from '../../models/documents.model';
import { FileUploadService } from '../../services/file-upload.service';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {

  documents: documentsList[] = [];
  uniqueFileNames: string[] = [];
  selectedVersion: { [key: string]: string } = {};
  selectedLanguage: { [key: string]: string } = {};
  versionsMap: { [key: string]: string[] } = {};
  languageMap: { [key: string]: { [key: string]: string[] } } = {}; // Adjusted

  private readonly getAllDocumentsObserver = {
    next: (data: CommonResponse<any>) => this.getAllDocumentsNext(data),
    error: (error: CommonResponse<any>) => this.getAllDocumentsError(error),
  }

  constructor(
    private _pageLoadingService: PageLoadingService,
    private _fileUploadService: FileUploadService,
  ) { }

  ngOnInit(): void {
    this._pageLoadingService.showLoadingGif();
    this._fileUploadService.getAllDocuments().subscribe(this.getAllDocumentsObserver);
  }

  getAllDocumentsNext(data: CommonResponse<any>) {
    this.documents = data.data;
    

    this.extractVersions();
    this.extractLanguages();
    this.extractUniqueFileNames();
    this.selectDefaultVersions();
    this._pageLoadingService.hideLoadingGif();
  }

  extractUniqueFileNames() {
    const fileNamesSet = new Set(this.documents.map(doc => doc.name));
    this.uniqueFileNames = Array.from(fileNamesSet);
  }

  extractVersions() {
    this.documents.forEach(doc => {
      if (!this.versionsMap[doc.name]) {
        this.versionsMap[doc.name] = [];
      }
      if (!this.versionsMap[doc.name].includes(doc.version)) {
        this.versionsMap[doc.name].push(doc.version);
      }
    });
  }

  extractLanguages() {
    this.documents.forEach(doc => {
      if (!this.languageMap[doc.name]) {
        this.languageMap[doc.name] = {};
      }
      if (!this.languageMap[doc.name][doc.version]) {
        this.languageMap[doc.name][doc.version] = [];
      }
      if (doc.language && !this.languageMap[doc.name][doc.version].includes(doc.language)) {
        this.languageMap[doc.name][doc.version].push(doc.language);
      }
    });
  }

  getAllDocumentsError(error: CommonResponse<any>) {
    
  }

  getVersions(fileName: string): string[] {
    return this.versionsMap[fileName] || [];
  }

  getLanguages(fileName: string): string[] {
    const selectedVersion = this.selectedVersion[fileName];
    if (selectedVersion) {
      return this.languageMap[fileName][selectedVersion] || [];
    }
    return [];
  }

  onVersionSelect(event: any, fileName: string) {
    this.selectedVersion[fileName] = event.target.value;
    // Reset the selected language when version changes
    this.selectedLanguage[fileName] = this.getLanguages(fileName)[0];
  }

  onLanguageSelect(event: any, fileName: string) {
    this.selectedLanguage[fileName] = event.target.value;
  }

  selectDefaultVersions() {
    this.uniqueFileNames.forEach(fileName => {
      const versions = this.getVersions(fileName);
      if (versions.length > 0) {
        if (!this.selectedVersion[fileName]) {
          this.selectedVersion[fileName] = versions[0];
        }
      }

      const languages = this.getLanguages(fileName);
      if (languages.length > 0) {
        if (!this.selectedLanguage[fileName]) {
          this.selectedLanguage[fileName] = languages[0];
        }
      }
    });
  }

  hasSelectedVersion(fileName: string): boolean {
    return !!this.selectedVersion[fileName];
  }

  isSelectedVersion(fileName: string, version: string): boolean {
    return this.selectedVersion[fileName] === version;
  }

  getDownloadUrl(fileName: string, version: string, language: string | undefined): string {
    const lang = language || 'default';
    return `${env.url_api}/${env.api_version}/documents/download/${fileName}/${version}/${lang}`;
  }
}
