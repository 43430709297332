import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeneralPaymentService {

  private processingPayment = new BehaviorSubject<boolean>(false);
  paymentProcessing$ = this.processingPayment.asObservable();

  startProcessing(): void {
    this.processingPayment.next(true);
    console.log("Entra a startProccessing");
  }

  stopProcessing(): void {
    this.processingPayment.next(false);
    console.log("Entra a stopProccessing");
  }
}
