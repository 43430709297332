import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { EmailValidator, FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Product } from 'src/app/books/models/product.model';
import { Country } from 'src/app/countries/models/country.model';
import { CountryService } from 'src/app/countries/services/country.service';
import { EditorialService } from 'src/app/editorials/services/editorial.service';
import { Language } from 'src/app/languages/models/language.model';
import { LanguageService } from 'src/app/languages/services/language.service';
import { DialogData } from 'src/app/shared/models/dialog-data.model';
import { CommonResponse } from 'src/app/shared/models/reponse.model';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable, map, startWith, switchMap, tap } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { UserRegister } from '../../models/user-register.model';
import { UserService } from '../../services/user.service';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { UserEdit } from '../../models/user-edit.model';

type UserUpdate = {
  userId: number;
  emailData: {
    email: string;
    update: boolean;
  };
  nameData: {
    name: string;
    update: boolean;
  };
  countryCodeData: {
    countryCode: string;
    update: boolean;
  };
  defaultLanguageCodeData: {
    defaultLanguageCode: string;
    update: boolean;
  };
  productsSelected: Product[];
};

@Component({
  selector: 'app-edit-users',
  templateUrl: './edit-users.component.html',
  styleUrls: ['./edit-users.component.scss']
})
export class EditUsersComponent {

  editUserForm: FormGroup;
  countries: Country[] = [];
  languages: Language[] = [];
  products: Product[] = [];
  productsSelected: Product[] = [];
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  filteredProducts: Observable<Product[]>;
  productCtrl = new FormControl('');
  user: UserRegister;
  userUpdate = {} as UserUpdate;

  @ViewChild('productInput') productInput: ElementRef<HTMLInputElement>;

  readonly getCountriesObserver = {
    next: (data: CommonResponse<any>) => this.getCountriesNext(data),
    error: (error: CommonResponse<any>) => this.getCountriesError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  readonly getLanguagesObserver = {
    next: (data: CommonResponse<any>) => this.getLanguagesNext(data),
    error: (error: CommonResponse<any>) => this.getLanguagesError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  readonly getAllProductsObserver = {
    next: (data: CommonResponse<any>) => this.getAllProductsNext(data),
    error: (error: CommonResponse<any>) => this.getAllProductsError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  readonly updateNewUserObserver = {
    next: (data: CommonResponse<any>) => this.updateNewUserNext(data),
    error: (error: CommonResponse<any>) => this.updateNewUserError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  readonly getUserObserver = {
    next: (data: CommonResponse<any>) => this.getUserNext(data),
    error: (error: CommonResponse<any>) => this.getUserError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  constructor(
    public dialogRef: MatDialogRef<EditUsersComponent>,
    private fb: FormBuilder,
    private _translateService: TranslateService,
    private _countryService: CountryService,
    private _languageService: LanguageService,
    private _editorialService: EditorialService,
    private _userService: UserService,
    private _pageLoadingService: PageLoadingService,
    private _snackbarService: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: { userId: number },
  ) {
    this._pageLoadingService.showLoadingGif();
    this.userUpdate.userId = data.userId;

    this._countryService.getAllCountries().subscribe(this.getCountriesObserver);
    this._languageService.getAllLanguages().subscribe(this.getLanguagesObserver);
    // this._editorialService.getAllproducts(2).subscribe(this.getAllProductsObserver);
    // this._userService.getUser(data.userId).subscribe(this.getUserObserver);

    this._editorialService.getAllproducts(2).subscribe(this.getAllProductsObserver);

    this._editorialService.getAllproducts(2).pipe(
      tap(() => {
        // Aquí puedes ejecutar código adicional si es necesario antes de la suscripción
      }),
      switchMap(() => {
        return this._userService.getUser(data.userId);
      })
    ).subscribe(this.getUserObserver);

    this.editUserForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required, Validators.email],
      password: ['', Validators.required],
      countryCode: ['default', Validators.required],
      defaultLanguageCode: ['default', Validators.required],
      defaultProducts: ['default']
    });
  }

  modalInteractTrue() {
    this.dialogRef.close(true);
  }

  registerUser(regForm: NgForm) { }

  getCountriesNext(data: CommonResponse<any>) {
    this.countries = data.data;
    this.editUserForm.get('countryCode')?.setValue(this.countries[0].countryCode);
  }

  getCountriesError(error: CommonResponse<any>) {
  }

  getLanguagesNext(data: CommonResponse<any>) {
    this.languages = data.data;
    this.editUserForm.get('defaultLanguageCode')?.setValue(this.languages[0].languageCode);
  }

  getLanguagesError(error: CommonResponse<any>) {
  }

  getAllProductsNext(data: CommonResponse<any>) {
    this.products = Object.values(data.data);

    this.autoCompleteFilteredProducts();

    this.editUserForm.get('defaultProducts')?.setValue(this.products);
  }

  getAllProductsError(error: CommonResponse<any>) {
  }

  getUserNext(data: CommonResponse<any>) {
    this.user = data.data;
    this.editUserForm.get('name')?.setValue(this.user.name);

    this.editUserForm.get('defaultLanguageCode')?.setValue(this.user.defaultLanguageCode);
    this.editUserForm.get('countryCode')?.setValue(this.user.countryCode);
    // Obtén la referencia al control de email
    const emailControl = this.editUserForm.get('email');

    // Almacena los validadores asincrónicos
    const asyncValidators = emailControl?.asyncValidator;

    // Quita temporalmente los validadores asincrónicos
    emailControl?.clearAsyncValidators();

    // Establece el valor
    emailControl?.setValue(this.user.email);

    // Restaura los validadores asincrónicos después de establecer el valor
    if (asyncValidators) {
      emailControl?.setAsyncValidators(asyncValidators);
    }

    this.editUserForm.get('defaultProducts')?.setValue(this.products);

    var productsUser: Array<any> = this.user.shopCartProducts;
    // Recorre el array productsUser
    for (const productUser of productsUser) {
      // Busca el producto correspondiente en el array products
      const productMatch = this.products.find(product => product.productId === productUser.productId);

      // Si encuentra una coincidencia
      if (productMatch) {
        // Busca el producto en productsSelected
        const selectedProductIndex = this.productsSelected.findIndex(selectedProduct => selectedProduct.productId === productUser.productId);

        // Si el producto no existe en productsSelected, agrégalo con quantity igual a 1
        if (selectedProductIndex === -1) {
          this.productsSelected.push({ ...productMatch, quantity: 1 });
        } else {
          // Si el producto ya existe en productsSelected, incrementa la cantidad en 1
          this.productsSelected[selectedProductIndex].quantity += 1;
        }
      }
    }

    this.userUpdate.nameData = { name: this.user.name, update: false };
    this.userUpdate.emailData = { email: this.user.email, update: false },
      this.userUpdate.countryCodeData = { countryCode: this.user.countryCode, update: false },
      this.userUpdate.defaultLanguageCodeData = { defaultLanguageCode: this.user.defaultLanguageCode, update: false },
      this.userUpdate.productsSelected = this.productsSelected;

    this.autoCompleteFilteredProducts();

    this.productInput.nativeElement.value = '';

    this.productCtrl.setValue(null);

    return;
  }

  getUserError(error: CommonResponse<any>) { }

  getCountryNameByLanguage(country: Country) {
    return country.country_general_info.find(cgi => cgi.language.languageIsoCode == this._translateService.currentLang)?.name;
  }

  add(event: MatChipInputEvent): void {
    this.autoCompleteFilteredProducts();

    // Clear the input value
    event.chipInput!.clear();
    this.productCtrl.setValue(null);
  }

  /** 
   * Eliminar un producto del selector de productos añadidos
   * @param product  elemento a eliminar del array
   */
  remove(product: any): void {
    const index = this.productsSelected.indexOf(product);

    if (index >= 0) {
      this.productsSelected.splice(index, 1);
    }
  }

  /** 
   * Seleccionar un producto del selector de productos a añadir
   * @param event  evento de tipo object con la información de la opción seleccionada
   */
  selected(event: MatAutocompleteSelectedEvent): void {

    if (!this.productsSelected.includes(event.option.value)) {
      this.productsSelected.push(event.option.value);
      this.productsSelected[this.productsSelected.length - 1].quantity = this.productsSelected[this.productsSelected.length - 1].availability > 0 ? 1 : 0;
    }

    this.autoCompleteFilteredProducts();

    this.productInput.nativeElement.value = '';

    this.productCtrl.setValue(null);
  }

  /** 
   * Cambiar la cantidad a asignar de un producto específico
   * @param event   evento de tipo object con la información de la opción seleccionada
   * @param change  opción de incrementar o disminuir la cantidad del producto
   */
  changeQuantity(event: Product, change: string) {
    change == 'lower' ? event.quantity-- : event.quantity++;
    this.productsSelected.map(product => {
      if (product.productId === event.productId) {
        product.quantity = event.quantity;
        return;
      }
    });
  }

  autoCompleteFilteredProducts() {
    this.filteredProducts = this.productCtrl.valueChanges.pipe(
      startWith(null),
      map((product: string | null) => (product ? this._filter(product) : this.products.slice())
      ),
    );
  }

  private _filter(value: string): Product[] {
    // Verificar si value es una cadena antes de llamar a toLowerCase()
    const filterValue = value && typeof value === 'string' ? value.toLowerCase() : '';
    return this.products.filter(p => p.product_general_info[0].title.toLowerCase().includes(filterValue));
  }

  getProductNameByLanguage(product: Product) {
    const currentLang = this._translateService.currentLang;
    const productTitles = product.product_general_info
      .filter(pgi => pgi.language.languageIsoCode == currentLang);

    if (productTitles.length > 0) {
      return productTitles[0].title;
    }

    return "";
  }

  updateNewUserNext(data: CommonResponse<any>) {
    if (data.statusCode >= 200 && data.statusCode < 300) {
      window.location.reload()
    }
  }

  updateNewUserError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    this._snackbarService.openStandardSnackBar(error.errors[0]);
  }

  editUserFromAdmin() {
    const productsId = this.productsSelected.map(x => x.productId);

    var productsQuantity = this.productsSelected.map(product => ({
      productId: product.productId,
      quantity: product.quantity
    }));

    if (this.user.name !== this.editUserForm.get("name")?.value) {
      this.userUpdate.nameData.name = this.editUserForm.get("name")?.value;
      this.userUpdate.nameData.update = true;
    }
    if (this.user.email !== this.editUserForm.get("email")?.value) {
      this.userUpdate.emailData.email = this.editUserForm.get("email")?.value;
      this.userUpdate.emailData.update = true;
    }
    if (this.user.countryCode !== this.editUserForm.get("countryCode")?.value) {
      this.userUpdate.countryCodeData.countryCode = this.editUserForm.get("countryCode")?.value;
      this.userUpdate.countryCodeData.update = true;
    }
    if (this.user.defaultLanguageCode !== this.editUserForm.get("defaultLanguageCode")?.value) {
      this.userUpdate.defaultLanguageCodeData.defaultLanguageCode = this.editUserForm.get("defaultLanguageCode")?.value;
      this.userUpdate.defaultLanguageCodeData.update = true;
    }

    console.log(this.userUpdate);

    this._userService.updateUserFromAdmin(this.userUpdate)
      .subscribe(this.updateNewUserObserver);

    this.dialogRef.close(true);
  }

}
