import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { PaymentMethodsComponent } from '../payment-methods/payment-methods.component';
import { GeneralPaymentService } from '../../services/general-payment.service';

@Component({
  selector: 'app-shipping-form',
  templateUrl: './shipping-form.component.html',
  styleUrls: ['./shipping-form.component.scss']
})
export class ShippingFormComponent implements OnInit, OnDestroy {
  shippingForm: FormGroup;
  billingForm: FormGroup;
  country: string = '';
  isBillingFormDisabled = true;
  isProcessingPayment: boolean = false;
  userId: number;
  orderPayment: any;
  private formSubscription: Subscription | null = null;

  @ViewChild(PaymentMethodsComponent) paymentMethodsComponent!: PaymentMethodsComponent;

  constructor(
    private fb: FormBuilder,
    private paymentService: GeneralPaymentService,
  ) { }

  ngOnInit(): void {
    this.shippingForm = this.fb.group({
      personType: ['', Validators.required],
      fullName: ['', Validators.required],
      idNumber: ['', Validators.required],
      country: ['', Validators.required],
      address: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]]
    });

    this.billingForm = this.fb.group({
      billingPersonType: [{ value: '', disabled: true }],
      billingFullName: [{ value: '', disabled: true }],
      billingIdNumber: [{ value: '', disabled: true }],
      billingCountry: [{ value: '', disabled: true }],
      billingAddress: [{ value: '', disabled: true }],
      billingEmail: [{ value: '', disabled: true }]
    });

    this.country = localStorage.getItem('countryName') || '';

    this.paymentService.paymentProcessing$.subscribe(isProcessing => {
      this.isProcessingPayment = isProcessing;
    });

    const loggedUser = sessionStorage.getItem('logged_user');
    let userId: number = 0;

    if (loggedUser) {
      try {
        const parsedData = JSON.parse(loggedUser);
        userId = parseInt(parsedData.userId, 10);
      } catch (error) {
        console.error('Error parsing userId from sessionStorage', error);
      }
    }

    this.userId = userId;
  }

  copyPersonalData(event: Event): void {
    const checkbox = event.target as HTMLInputElement;

    if (checkbox.checked) {
      this.billingForm.patchValue({
        billingPersonType: this.shippingForm.get('personType')?.value || '',
        billingFullName: this.shippingForm.get('fullName')?.value || '',
        billingIdNumber: this.shippingForm.get('idNumber')?.value || '',
        billingCountry: this.shippingForm.get('country')?.value || '',
        billingAddress: this.shippingForm.get('address')?.value || '',
        billingEmail: this.shippingForm.get('email')?.value || ''
      });

    
      this.billingForm.disable();
      this.formSubscription = this.shippingForm.valueChanges.subscribe(values => {
        this.billingForm.patchValue({
          billingPersonType: values.personType,
          billingFullName: values.fullName,
          billingIdNumber: values.idNumber,
          billingCountry: values.country,
          billingAddress: values.address,
          billingEmail: values.email
        });
      });
    } else {
    
      this.billingForm.enable();
      this.billingForm.reset();

      if (this.formSubscription) {
        this.formSubscription.unsubscribe();
        this.formSubscription = null;
      }
    }
  }

  ngOnDestroy(): void {
  
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }
}
