import { Component, OnInit } from '@angular/core';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { ShopCartProduct, producShippingCost } from '../../models/shop-cart-product.model';
import { CountryInfo } from '../../models/country-info.model';
import { ShopCartService } from '../../services/shop-cart.service';
import { CommonResponse } from 'src/app/shared/models/reponse.model';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';
import { LoginService } from 'src/app/auth/services/logIn.service';
import { ShopProductsService } from '../../services/shop-products.service';
import { TranslateService } from '@ngx-translate/core';
import { EditorialService } from 'src/app/editorials/services/editorial.service';
import { GeneralPaymentService } from 'src/app/payment/services/general-payment.service';
@Component({
  selector: 'app-shop-cart',
  templateUrl: './shop-cart.component.html',
  styleUrls: ['./shop-cart.component.scss'],
})
export class ShopCartComponent implements OnInit {
  distance: number;
  shippingPrice: number;
  taxes: number;
  totalCost: number;
  percentage: number;
  countriesNames: string[] = [];
  shopCartBooks: ShopCartProduct[] = [];
  CountryInfo: CountryInfo[] = [];
  countries: string[] = ['Colombia', 'España', 'Mexico', 'Portugal','United States'];
  currencySymbol: string = '$';
  finalPrice: number = 0;
  productShippingCost: producShippingCost[] = [];
  productsId: number[] = [];
  totalShippingCost: number = 0
  mxn: string;
  isLogin: boolean;


  private readonly getCartProductsObserver = {
    next: (data: CommonResponse<any>) => this.getCartProductsNext(data),
    error: (error: CommonResponse<any>) => this.getCartProductsError(error),
    complete: () => this._pageLoadingService.hideLoadingGif(),
  };

  private readonly getShopCountryObserver = {
    next: (data: CommonResponse<any>) => this.getCountryData(data),
    error: (error: CommonResponse<any>) => this.getCountryDataError(error),
    complete: () => this._pageLoadingService.hideLoadingGif(),
  };

  private readonly editorialCountriesObserver = {
    next: (data: CommonResponse<any>) => this.getCountryInfoNext(data),
    error: (error: CommonResponse<any>) => this.getCountryInfoError(error),
    complete: () => this._pageLoadingService.hideLoadingGif(),
  };

  readonly getShippingPriceObserver = {
    next: (data: CommonResponse<any>) => this.getShippingPriceNext(data),
    error: (error: CommonResponse<any>) => this.getShippingPriceError(error)
  }

  constructor(
    private _snackBarService: SnackbarService,
    private _shopProductsService: ShopProductsService,
    private _shopCartService: ShopCartService,
    private _pageLoadingService: PageLoadingService,
    private _editorialService: EditorialService,
    private _loginService: LoginService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this._pageLoadingService.showLoadingGif();
    this._shopCartService.getCartProducts()
      .subscribe(this.getCartProductsObserver);

    this._shopProductsService.getCountryData()
      .subscribe(this.getShopCountryObserver);

    this._editorialService.getEditorialCountries()
      .subscribe(this.editorialCountriesObserver);

    this.isLogin = !!this.isLoggedUser();
  }

  isLoggedUser() {
    return this._loginService.isLogged();
  }

  getCountryInfoNext(data: CommonResponse<any>) {
    this.CountryInfo = data.data;

    const countryNameStorage = localStorage.getItem('countryName');

    if (countryNameStorage !== null) {
      const country = this.CountryInfo.find(country => country.countryInfo.name === countryNameStorage);

    }
  }

  getCountryInfoError(error: CommonResponse<any>) {
    console.log('ERROR', error);
  }

  getCartProductsNext(data: CommonResponse<any>) {
    this.shopCartBooks = data.data;

    this.shopCartBooks.map(this._shopCartService.setShopcartProductQuantity);
    this.currencySymbol = this.shopCartBooks[0].price.currencySymbol;

    this.shopCartBooks.forEach(item => {
      if (item.product && item.product.productId) {
        this._shopCartService.getProductShippingCost(item.product.productId)
          .subscribe(this.getShippingPriceObserver);
      }
    });
  }

  getShippingPriceNext(data: CommonResponse<any>) {
    this.productShippingCost = data.data;

    this.productShippingCost.forEach((cost: any) => {
      this.totalShippingCost += parseFloat(cost.price);
    });
  }

  getShippingPriceError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    let storedCountryName = localStorage.getItem('countryName');
    if (error.statusCode == 404) {
      if (storedCountryName && storedCountryName.toLowerCase() === 'españa') {
        this._snackBarService.openStandardSnackBar(
          'getCartProductsError404_spain'
        );
      } else {
        this._snackBarService.openStandardSnackBar('getCartProductsError404');
      }
    } else {
      if (storedCountryName && storedCountryName.toLowerCase() === 'españa') {
        this._snackBarService.openStandardSnackBar(
          'getProductsPriceError_spain'
        );
      } else {
        this._snackBarService.openStandardSnackBar('getCartProductsError');
      }
    }
  }

  getCountryData(data: CommonResponse<any>) {
    let storedCountryName = localStorage.getItem('countryName');
    if (storedCountryName == 'Mexico') {
      this.mxn = "MXN"
    }

    const userCountry = this.countries.find((country) => storedCountryName);
  }

  getTranslatedText(key: string): string {
    let storedCountryName = localStorage.getItem('countryName');
    if (storedCountryName && storedCountryName.toLowerCase() === 'españa') {
      return this.translateService.instant(key + '_spain');
    } else {
      return this.translateService.instant(key);
    }
  }

  getCountryDataError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    let storedCountryName = localStorage.getItem('countryName');
    if (error.statusCode == 404) {
      if (storedCountryName && storedCountryName.toLowerCase() === 'españa') {
        this._snackBarService.openStandardSnackBar(
          'getCartProductsError404_spain'
        );
      } else {
        this._snackBarService.openStandardSnackBar('getCartProductsError404');
      }
    } else {
      if (storedCountryName && storedCountryName.toLowerCase() === 'españa') {
        this._snackBarService.openStandardSnackBar(
          'getCartProductsError_spain'
        );
      } else {
        this._snackBarService.openStandardSnackBar('getCartProductsError');
      }
    }
  }

  getCartProductsError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    let storedCountryName = localStorage.getItem('countryName');
    if (error.statusCode == 404) {
      if (storedCountryName && storedCountryName.toLowerCase() === 'españa') {
        this._snackBarService.openStandardSnackBar(
          'getCartProductsError404_spain'
        );
      } else {
        this._snackBarService.openStandardSnackBar('getCartProductsError404');
      }
    } else {
      if (storedCountryName && storedCountryName.toLowerCase() === 'españa') {
        this._snackBarService.openStandardSnackBar(
          'getCartProductsError_spain'
        );
      } else {
        this._snackBarService.openStandardSnackBar('getCartProductsError');
      }
    }
  }
  deleteProductEventHandler(event: any) {
    this._shopCartService.deleteProductFromCart(event);
    const indexToDelete = this.shopCartBooks.findIndex(
      (x) => x.product.productId == event
    );
    this.shopCartBooks.splice(indexToDelete, 1);
  }

  getTotalTaxes() {
    //over all cartBooks sum all taxes
    let taxes = 0;
    this.shopCartBooks.forEach((p) => {
      taxes += Number(p.price.taxes) * p.quantity;
    });

    return taxes;
  }
  getTotalTaxesPercentage() {
    let taxes = this.taxes;
    return taxes;
  }

  getPercentageTaxes() {
    this.percentage = this.taxes / 100;

    return this.percentage;
  }

  getTotalCostWithoutTaxes() {
    let totalCost = 0;

    this.shopCartBooks.forEach((p) => {
      this.totalCost = totalCost += Number(p.price.amount) * p.quantity;
    });

    return totalCost;
  }

  getTotalPrice() {
    this.finalPrice = 0;

    this.shopCartBooks.forEach((p) => {
      this.finalPrice += (Number(p.price.amount) + Number(p.shippingPrice) + Number(p.price.taxes)) * p.quantity;
    });

    let finalPriceWhithShipping: number = 0
    finalPriceWhithShipping = this.finalPrice

    return finalPriceWhithShipping;
  }

  getTotalCostWithTaxes() {
    return this.getTotalPrice();
  }

  setCurrencySymbol() {
    this.currencySymbol = this.shopCartBooks[0].price.currencySymbol;
  }
}
