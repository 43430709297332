import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, catchError, map } from 'rxjs';
import { ResponseHelper } from 'src/app/shared/helpers/response.helper';
import { environment as env } from 'src/environments/environment';
// import { CategoryRegister } from '../models/category-register.model';
import { CategoryRegister } from 'src/app/books/models/category-product.models';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  constructor(private _httpClient: HttpClient) {}

  getAllCategories(categoryId : number) {
    return this._httpClient.get(`${env.url_api}/${env.api_version}/category/list/${categoryId}`,  { observe: 'response' as 'response'})
    .pipe(
      catchError(error => {
        throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
      }),
      map((response: HttpResponse<any>) => {
      return ResponseHelper.generateCommonResponse(response);
    }));
  }

  createNewCategory(category: CategoryRegister){
    
    return this._httpClient.post(`${env.url_api}/${env.api_version}/category/add`, category, { observe: 'response' as 'response'})
    .pipe(
      catchError(error => {
        throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
      }),
      map((response: HttpResponse<any>) => {
      return ResponseHelper.generateCommonResponse(response);
    }));
  }

  updateCategory(categoryUpdate: object){
    return this._httpClient.post(`${env.url_api}/${env.api_version}/category/update`, categoryUpdate, { observe: 'response' as 'response'})
    .pipe(
      catchError(error => {
        throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
      }),
      map((response: HttpResponse<any>) => {
        return ResponseHelper.generateCommonResponse(response);
    }));
  }

}
