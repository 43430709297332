<hr />
<div class="row d-flex justify-content-evenly">
  <div class="col-5">
    <svg class="addIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 229.71 208.21">
      <g id="Capa_2" data-name="Capa 2">
        <g id="Capa_1-2" data-name="Capa 1">
          <path
            d="M0,33.08c5-8.32,10.25-8.6,16.6-.87L22.15,39C34.51,27.43,46.52,16.22,58.54,5a29.2,29.2,0,0,1,4.13-3.59c3.54-2.25,7.09-1.85,10,1.1s3.18,6.53.88,10a21.52,21.52,0,0,1-3.42,3.6Q49.53,35.41,28.91,54.63c-6.32,5.88-10.33,5.66-15.68-1.12C8.71,47.79,4.4,41.9,0,36.08Z" />
          <path
            d="M0,182.08c5.58-8.06,10.45-8.16,16.76-.36,1.66,2.06,3.34,4.1,5.35,6.57,2.49-2.31,4.77-4.41,7-6.53,10.48-9.77,20.91-19.58,31.44-29.29,4.36-4,9-4.05,12.33-.37s2.84,8.05-1.26,11.91q-22,20.7-44.22,41.23c-4.58,4.23-8.93,3.94-12.9-.86-5-6-9.7-12.19-14.53-18.3Z" />
          <path
            d="M0,108.08c4.59-8.48,10.28-9,16.43-1.39,1.77,2.18,3.55,4.34,5.68,6.93,2.67-2.47,5.09-4.68,7.49-6.91,10.36-9.66,20.68-19.35,31.08-29,4-3.72,8.76-3.85,12-.56,3.47,3.53,3.14,8.25-1.13,12.27q-21.68,20.37-43.49,40.6c-5.38,5-9.68,4.56-14.32-1.27S4.57,117,0,111.08Z" />
          <path
            d="M168.32,112.1c-17.5,0-35,.2-52.49-.22-2.73-.06-6.36-2.38-7.77-4.74-1.13-1.89-.32-6,1.11-8.06,1.19-1.76,4.52-2.88,6.91-2.89,34.83-.18,69.66-.14,104.49-.1,5.4,0,9,3.28,9.12,7.87.09,4.75-3.67,8.1-9.37,8.12C203,112.13,185.65,112.1,168.32,112.1Z" />
          <path
            d="M168.16,37.35q-25.49,0-51,0c-6.46,0-10.06-2.8-10.18-7.73s3.73-8.28,10.26-8.29q51.24,0,102.47,0c6.35,0,10.32,3.57,9.94,8.62-.35,4.61-3.78,7.37-9.52,7.39C202.82,37.38,185.49,37.35,168.16,37.35Z" />
          <path
            d="M168,170.81q25.74,0,51.49,0c6.43,0,10.08,2.82,10.22,7.72s-3.81,8.3-10.29,8.3q-51.24,0-102.48,0c-6,0-9.63-2.91-9.91-7.63s3.28-8.34,9-8.37C133.33,170.77,150.66,170.82,168,170.81Z" />
        </g>
      </g>
    </svg>
    <p class="subtitlesAddBook d-flex justify-content-center">{{'category.add_category' | translate}}</p>
    <p class="smalltitlesAddbok d-flex justify-content-center">{{'category.add_category_crp' | translate}}
    </p>
  </div>
  <div class="col-7">
    <div class="row">
      <div id="panelsStayOpen-collapseThree" class="accordion-collapse" aria-labelledby="panelsStayOpen-headingThree">
        <div class="accordion-body">
          <div class="row">

            <!-- TABLE -->
            <table mat-table matSort #categoryTableSort="matSort" #categoryTable [dataSource]="categoryDataSource"
              class="table mb-4 w-100">

              <ng-container matColumnDef="index">
                <th mat-header-cell *matHeaderCellDef class="sizeThead">#</th>
                <td mat-cell *matCellDef="let element">{{element.index}}</td>
              </ng-container>

              <ng-container matColumnDef="language">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by language"
                  class="sizeThead">{{'create_product.language_crp' | translate}}</th>
                <td mat-cell *matCellDef="let element">{{element.languageName}}</td>
              </ng-container>

              <ng-container matColumnDef="categoryName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name"
                  class="sizeThead">{{'category.category_name' | translate}}</th>
                <td mat-cell *matCellDef="let element">{{element.categoryName}}</td>
              </ng-container>

              <!-- actions Column -->
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="sizeThead">{{'create_product.actions_crp' | translate}}
                </th>
                <td mat-cell *matCellDef="let element">
                  <button class="btn" (click)="setCategoryFormToEdit(element.index)">
                    <i class="bi bi-pencil-fill colorIconAction"></i>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="categoryDisplayedColumns" style="height: 40px;"></tr>
              <tr mat-row *matRowDef="let row; columns: categoryDisplayedColumns;"></tr>

              <!-- Row shown when there is no matching data that will be provided to the wrapper table. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell no-data-centered" colspan="6">No data</td>
              </tr>
            </table>


          </div>
          <div [formGroup]="categoryForm">
            <!-- Nombre Categoría -->
            <div class="row">
              <label class="labelInputs" for="">{{'category.category_name' | translate}}</label>
              <input class="inputSize customInput" type="text" name="categoryName" id="categoryName"
                formControlName="categoryName" placeholder="{{'auth.register.name' | translate}}">
            </div>
            <div class="row">
              <div class="col-6 dropDown">
                <label class="labelInputs" for="">{{'create_product.language_crp' | translate}}</label>
                <mat-form-field appearance="fill" class="customDropDown">
                  <mat-label>{{'create_product.language_crp' | translate}}</mat-label>
                  <mat-select formControlName="language">
                    <mat-option *ngFor="let languageItem of validAvailableLanguages" [value]="languageItem"
                      (click)="showCurrentLanguage(languageItem)">
                      {{languageItem.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-6 dropDown">
                <label class="labelInputs" for="">{{'category.status' | translate}}</label>
                <!-- <select class="inputslogin" id="floatingSelect" formControlName="status">
                <option value="Activo">{{'category.active' | translate}}</option>
                <option value="Inactivo">{{'category.disable' | translate}}</option>
                </select> -->
                <mat-form-field appearance="fill" class="customDropDown">
                  <mat-label>{{'category.status' | translate}}</mat-label>
                  <mat-select formControlName="status">
                    <mat-option value="Activo">
                      {{'category.active' | translate}}
                    </mat-option>
                    <mat-option value="Inactivo">
                      {{'category.disable' | translate}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <!-- <div class="row">
              <mat-form-field appearance="fill" class="inputSize">
                <mat-label>{{'create_product.language_crp' | translate}}</mat-label>
                <mat-select formControlName="language">
                  <mat-option *ngFor="let languageItem of validAvailableLanguages" [value]="languageItem"
                    (click)="showCurrentLanguage(languageItem)">
                    {{languageItem.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
                </div> -->
            </div>



            <div class="row">
              <div class="col">
                <button *ngIf="isUpdatingCategory" (click)="editCategoryData()"
                  class="mat-raised-button mat-button-base lg-i3book-button">
                  {{'update_product.update_up' | translate}}
                </button>
                <button *ngIf="isUpdatingCategory" (click)="discardCategoryEdit()"
                  class="mat-raised-button mat-button-base lg-i3book-button-white">
                  {{'create_product.discard_crp' | translate}}
                </button>
                <button *ngIf="!isUpdatingCategory" mat-raised-button (click)="addCategoryData()"
                  class="mat-raised-button mat-button-base lg-i3book-button">
                  {{'create_product.save_crp' | translate}}
                </button>
              </div>
            </div>

            <br><br>
            <div class="row p-3">
              <div class="col text-center">
                <button class="lg-i3book-button send" type="button" (click)="onSubmit()">{{'create_product.send' | translate}}</button>
                <button class="lg-i3book-button white send" (click)="close()">{{'update_product.close_up' | translate}}</button>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</div>
<!-- </div> -->