import { Component, OnInit } from '@angular/core';
import { FileUploadService } from '../../services/file-upload.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';
import { CommonResponse } from 'src/app/shared/models/reponse.model';
import { documentsList } from '../../models/documents.model';


@Component({
  selector: 'app-edit-documents',
  templateUrl: './edit-documents.component.html',
  styleUrls: ['./edit-documents.component.scss'],
})
export class EditDocumentsComponent implements OnInit {

  selectedFile: File | null;
  documents: documentsList[] = [];
  fileForm: FormGroup;

  private readonly getAllDocumentsObserver = {
    next: (data: CommonResponse<any>) => this.getAllDocumentsNext(data),
    error: (error: CommonResponse<any>) => this.getAllDocumentsError(error),
  }

  constructor(
    private fb: FormBuilder,
    private _fileUploadService: FileUploadService,
    private _snackbarService: SnackbarService,
    private _pageLoadingService: PageLoadingService
  ) {
    this._pageLoadingService.showLoadingGif();
    this._fileUploadService.getAllDocuments().subscribe(this.getAllDocumentsObserver)
  }

  ngOnInit(): void {
    this.fileForm = this.fb.group({
      version: ['', Validators.required],
      languaje: ['', Validators.required],
      file: ['', Validators.required],
    })
  }

  generateFormdataForRequest() { }
  onFileChange(event: any) {
    this.selectedFile = <File>event.target.files[0];

    // Verificar si el archivo es un PDF
    if (this.selectedFile && this.selectedFile.type !== 'application/pdf') {
      this._snackbarService.openStandardSnackBar('documentsError');
      this.selectedFile = null;
    }
  }

  convertFileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  }

  async onSubmit() {
    this._pageLoadingService.showLoadingGif();

    if (this.selectedFile) {

      const base64 = await this.convertFileToBase64(this.selectedFile);

      // Elimina el prefijo data URL (base64)
      const base64Data = base64.split(',')[1];

      if (base64Data) {
        const newDocumentData: any = {
          version: this.fileForm?.get('version')?.value,
          languaje: this.fileForm?.get('languaje')?.value,
          name: this.selectedFile.name,
          file: base64Data,
        };

        
        

        // this._fileUploadService.uploadBase64File(newDocumentData)
        this._fileUploadService.upload2(newDocumentData).subscribe(response => {
          if (response.statusCode === 200) {
            this._snackbarService.openStandardSnackBar('file_success');
            this._fileUploadService.getAllDocuments().subscribe(this.getAllDocumentsObserver);
            this._pageLoadingService.hideLoadingGif();
          } else {
            this._snackbarService.openStandardSnackBar('file_error');
            this._pageLoadingService.hideLoadingGif();
          }
        },
        error => {
          this._snackbarService.openStandardSnackBar('file_error');
          this._pageLoadingService.hideLoadingGif();
        }
      );
    }
  }
  }

  deleteDocument(id: any) {
    this._pageLoadingService.showLoadingGif();
      this._fileUploadService.deleteDocument(id).subscribe(response => {
        if (response.statusCode === 200) {
          this._snackbarService.openStandardSnackBar('file_delete');
          this._fileUploadService.getAllDocuments().subscribe(this.getAllDocumentsObserver)
          this._pageLoadingService.hideLoadingGif();
        } else {
          this._snackbarService.openStandardSnackBar('file_delete_error');
          this._pageLoadingService.hideLoadingGif();
        }
      },
      error => {
        this._snackbarService.openStandardSnackBar('file_delete_error');
        this._pageLoadingService.hideLoadingGif();
      }
    );
  }

  getAllDocumentsNext(data: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();

    this.documents = data.data;

  }

  getAllDocumentsError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();

  }

}
